<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Volumes</h1>
                <p class="text-caption text-center">All volumes to which you have read or write access across all linked accounts.</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- <UserVolumeList/> -->
                    <v-simple-table v-if="linkUserVolumeList">
                        <template #default>
                            <thead>
                                <tr>
                                    <th>Account</th>
                                    <th>Volume</th>
                                    <th>Read</th>
                                    <th>Write</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(linkUserVolume, idx) in linkUserVolumeList" :key="idx">
                                    <td>
                                        <router-link :to="{ name: 'account-dashboard', params: { accountId: linkUserVolume.volume_account_id } }">{{ linkUserVolume.account_name }}</router-link>
                                    </td>
                                    <td>
                                        <router-link :to="{ name: 'account-edit-volume', params: { accountId: linkUserVolume.volume_account_id, volumeId: linkUserVolume.volume_id } }">
                                        {{ linkUserVolume.volume_label }}</router-link>
                                    </td>
                                    <td>
                                        <!-- <v-checkbox :input-value="linkUserVolume.permit_read" @change="editLinkUserVolumePermitRead(linkUserVolume, idx)" :disabled="!isAdmin"/> -->
                                        <template v-if="linkUserVolume.permit_read">
                                            <font-awesome-icon :icon="['fas', 'check']" color="green"/>
                                        </template>
                                        <!-- <template v-if="!linkUserVolume.permit_read">
                                            <font-awesome-icon :icon="['fas', 'check']" color="red"/>
                                        </template> -->
                                        <!-- <template v-if="isPermitServiceAdmin || isPermitAccountAdmin">
                                            <font-awesome-icon :icon="['fas', 'pencil-alt']" color="black"/>
                                        </template> -->
                                    </td>
                                    <td>
                                        <!-- <v-checkbox :input-value="linkUserVolume.permit_write" @change="editLinkUserVolumePermitWrite(linkUserVolume, idx)" :disabled="!isAdmin"/> -->
                                        <template v-if="linkUserVolume.permit_write">
                                            <font-awesome-icon :icon="['fas', 'check']" color="green"/>
                                        </template>
                                        <!-- <template v-if="!linkUserVolume.permit_write">
                                            <font-awesome-icon :icon="['fas', 'check']" color="red"/>
                                        </template> -->
                                        <!-- <template v-if="isPermitServiceAdmin || isPermitAccountAdmin">
                                            <font-awesome-icon :icon="['fas', 'pencil-alt']" color="black"/>
                                        </template> -->
                                    </td>
                                    <td>
                                        <v-menu offset-y left>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon v-on="on">
                                                    <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                                </v-btn>
                                            </template>
                                            <v-list class="ma-0 pa-0">
                                                <v-list-item-group>
                                                <v-list-item :to="{ name: 'user-check-volume', params: { userId: linkUserVolume.user_id }, query: { id: linkUserVolume.volume_id } }">
                                                    <v-list-item-title>Check access</v-list-item-title>
                                                </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                                <!-- <CheckTableRow item="Username assigned" :status="isUsernameAssigned" yesno :comment="user.ssh_alias"/>
                                <CheckTableRow item="UID assigned" :status="isUsernameCreated" yesno :progress="isCheckInProgress" :comment="status.uid"/>
                                <CheckTableRow item="Password" :status="isPasswordEnabled" abled :progress="isCheckInProgress"/>
                                <CheckTableRow item="Public Key" :status="isPublicKeyEnabled" abled :progress="isCheckInProgress"/> -->
                                <!-- TODO: button to open dialog and show the public key (remember, admin might use this to look at a user's ssh status also, and the admin doesn't go to the user's profile page, so this is the only place to see it) -->
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
// import { mapState } from 'vuex';
// import UserVolumeList from '@/components/user-dashboard/UserVolumeList.vue';

export default {
    components: {
        // UserVolumeList,
    },
    data: () => ({
        error: null,
        linkUserVolumeList: null,
    }),
    computed: {
        // ...mapState({
        //     session: (state) => state.session,
        // }),
    },
    methods: {
        async loadLinkUserVolumeList() {
            try {
                this.$store.commit('loading', { loadLinkUserVolumeList: true });
                const response = await this.$client.user(this.$route.params.userId).linkUserVolume.search(/* { user_id: this.$route.params.userId } */);
                console.log(`loadLinkUserVolumeList: response ${JSON.stringify(response)}`);
                if (response?.list) {
                    this.linkUserVolumeList = response.list.map((item) => {
                        const copy = { ...item };
                        copy.label = `${item.user_display_name} (${item.user_email})`;
                        copy.permit_read = item.permit?.mount?.includes('read');
                        copy.permit_write = item.permit?.mount?.includes('write');
                        return copy;
                    });
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load LinkUserVolumeList', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load volume list' });
            } finally {
                this.$store.commit('loading', { loadLinkUserVolumeList: false });
            }
        },
    },
    mounted() {
        this.loadLinkUserVolumeList();
    },
};
</script>
